import "react-app-polyfill/stable";
import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Provider as StoreProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import DndProvider from "./components/DndProvider";

import { initAuth, signOut } from "./redux/auth/actions";
import createStore, { browserHistory } from "./redux/createStore";
import theme, { useGlobalStyles } from "./theme";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { setExcludeDeprecated } from "./redux/app/actions";

localStorage.removeItem("tmr/overview-table/support");

function GlobalStylesProvider(props: React.PropsWithChildren<unknown>) {
    useGlobalStyles();
    return <>{props.children!}</>;
}

const store = createStore();
store.dispatch(initAuth());
window.addEventListener('storage', () => {
    if (window.localStorage.getItem('tmr/token') && window.location.pathname==="/account/signin"){
        window.location.reload();
    }
    else if (!window.localStorage.getItem('tmr/token') && window.location.pathname!=="/account/signin"){
             store.dispatch(signOut());
    }           
})
const excludeDeprecated = localStorage.getItem("excludeDeprecated");//exclude deprecated devices
if (excludeDeprecated !== null ) {
    store.dispatch(setExcludeDeprecated({excludeDeprecated: excludeDeprecated === "true"}));
}
ReactDOM.render(
    <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
            <DndProvider>
                <GlobalStylesProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <ConnectedRouter history={browserHistory}>
                            <React.StrictMode>
                                <App />
                            </React.StrictMode>
                        </ConnectedRouter>
                    </MuiPickersUtilsProvider>
                </GlobalStylesProvider>
            </DndProvider>
        </ThemeProvider>
    </StoreProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
