import {   Button,  CircularProgress,  FormControl,  Grid,  InputLabel,  MenuItem,  Select,  Switch,  TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { useAsync, useToast } from "src/hooks";
import { customersArraySelector, userSelector } from "src/redux/app/selectors";
import DeviceDataService from "src/services/DeviceDataService";
import QCService, { Chapter } from "src/services/QCService";

function b64toBlob(b64Data:string, contentType:any, sliceSize:any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  export type QCquestion={
    name:string,
    description:Array<{image:string|Blob,text:string}>,
    questionConfig:{
        type:string,
        pictureRequired:string,
        subtype:string,
    },
    robotCommand?:any,
    robotTest?:any,
    robotConnect?:any
}


export function QCApp() {
    const [workflow,setWorkflow]=useState(undefined as any)
    const user=useSelector(userSelector)
    const [uploading,setUploading]=useState(false)
    const customers=useSelector(customersArraySelector)

    const fetchChapters = useCallback(
        () => QCService.getWorkflows()
        ,[]
    );
    const {value:workflows} = useAsync(fetchChapters, {
        defaultPending: true,
    });
    const fetchKeys = useCallback(
        () => DeviceDataService.getDeviceDataKeys().then((r)=>[...new Set(r)]),
        []
    );
    const { value:keys } = useAsync(fetchKeys, {
        defaultPending: true,
    });
    
    const { displayToast } = useToast();

    const displayErrorToast = (val:any) => displayToast({
        message: val.message,
        severity: "error",
        withCloseIcon: true,
    });

    const submit=async (e:any)=>{
        setUploading(true)
        e.preventDefault()
        let versionUp=false;
        for (let i=0;i< workflow.chapters.length;i++)
        {
            if (workflow.chapters[i].updateVersion)
            {
                versionUp=true
                workflow.chapters[i].version=(Number(workflow.chapters[i].version)+1).toString()
                delete workflow.chapters[i].updateVersion
            }
        }
        workflow.version=(Number(workflow.version)+1).toString()
        workflow.created=(new Date().valueOf()).toString()
        workflow.createdBy=user.username

        for (let i=0;i< workflow.chapters.length;i++)
        {
            for (let j=0;j< workflow.chapters[i].questions.length;j++)
            {
                for (let k=0;k< workflow.chapters[i].questions[j].description.length;k++)
                {
                    if (workflow.chapters[i].questions[j].description[k].image)
                    {
                        workflow.chapters[i].questions[j].description[k].image=btoa(String.fromCharCode(...new Uint8Array(await (workflow.chapters[i].questions[j].description[k].image as Blob).arrayBuffer())))
                    }
                }
            }
        }
        

  
        try {
            
            QCService.postWorkflow(workflow).then((r)=>{
                displayToast({message:"Changes are saved",severity:"success"});
                window.location.reload()
            })
        } catch (error) {
            displayErrorToast(error)
        }
        setUploading(false)
    }
    return (
        <Grid container direction="column" style={{padding:20}}>
            <Typography variant="h5">QC App thingy</Typography>
           
            <form >
                <Grid container direction="column" spacing={2} style={{padding:50}}>
                    <Grid item>
                        <AutocompleteV2
                            options={workflows??[]}
                            renderInput={
                                params => 
                                { 
                                return <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Edit existing workflow (sorted by latest update)"
                                />}
                            }
                            getOptionLabel={((c:any)=>c.id+' '+c.name)}
                            getOptionSelected={(a, b) => a.name === b.name}
                            renderOption={(c)=>{
                            return <div>{c.id + ' '+c.name+"("+c.filter.serialNumberFilterRange[0].start+'-'+c.filter.serialNumberFilterRange[0].end+")" }</div>}}
                            onChange={async (_e, val) => {
                                if (val)
                                {
                                    for (let i=0;i< val.chapters.length;i++)
                                    {
                                        for (let j=0;j< val.chapters[i].questions.length;j++)
                                        {
                                            for (let k=0;k< val.chapters[i].questions[j].description.length;k++)
                                            {
                                                if (val.chapters[i].questions[j].description[k].image)
                                                {
                                                    val.chapters[i].questions[j].description[k].image=b64toBlob(await QCService.getImage(val.chapters[i].questions[j].description[k].image),"image/jpg",512)
                                                }
                                            }
                                        }
                                    }
                                    setWorkflow(val)
                                }
                                else
                                {
                                    setWorkflow(undefined)
                                }
                            }}
                            style={{marginBottom:"10px",width:"400px"}}
                        ></AutocompleteV2>
                    </Grid>

                    
                    <Grid item container spacing={2}>
                    {!workflows?<Grid style={{width:"100%"}}><CircularProgress ></CircularProgress></Grid>:
                    workflow && (workflow.chapters.length===0?
                        <Button size="small" variant="contained" color="primary" onClick={(e)=>{
                            workflow.chapters.push({name:"",version:"1",allowedUsers:[],questions:[]})
                            setWorkflow({...workflow})
                            }} style={{width:'200px'}}>Add chapter</Button>
                    :<Grid container spacing={2} direction="column">
                        <Grid item>
                                <TextField label="Workflow name" variant="outlined"  onChange={(e)=>{workflow.name=e.target.value;setWorkflow({...workflow})}} required size="small" value={workflow.name} style={{width:'400px'}} disabled={!workflow || uploading}/>
                            </Grid>
                            <Grid item >
                                <TextField label="Serial number min" variant="outlined"  onChange={(e)=>{workflow.filter.serialNumberFilterRange[0].start=e.target.value;setWorkflow({...workflow})}} required size="small" value={workflow.filter.serialNumberFilterRange?.[0].start} style={{width:'400px',marginRight:5}} disabled={!workflow || uploading}/>
                                <TextField label="Serial number max" variant="outlined"  onChange={(e)=>{workflow.filter.serialNumberFilterRange[0].end=e.target.value;setWorkflow({...workflow})}} required size="small" value={workflow.filter.serialNumberFilterRange?.[0].end} style={{width:'400px'}} disabled={!workflow || uploading}/>
                            </Grid>
                   { workflow.chapters.map((chapter:any,ind:number)=><Grid item container spacing={2} key={ind}>
                        <Grid container>
                            
                            <Accordion style={{width:"100%"}}>
                                <AccordionSummary style={{border:'2px solid #aaa',margin:"5px",borderRadius:5}}> 
                                    <Grid container direction="row" spacing={2} style={{ justifyContent:"space-between"}}>
                                        <Typography>{"Chapter "+ind +'. '+chapter.name}</Typography> 
                                        <Button size="small" variant="contained" color="primary" onClick={(e)=>{
                                            e.stopPropagation()
                                            workflow.chapters=[...workflow.chapters.slice(0,ind+1),{name:"",version:"1",allowedUsers:[],questions:[]},...workflow.chapters.slice(ind+1)]
                                            setWorkflow({...workflow})
                                            }} style={{width:'200px'}}>Add chapter ↓</Button>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid item container direction="row">
                                    <InputLabel id="select-label6" style={{marginLeft:12,alignContent:"center",color:"#000"}}>Update version</InputLabel>
                                    <Switch checked={workflow.chapters[ind].updateVersion??false} onChange={()=>{
                                        workflow.chapters[ind].updateVersion=!workflow.chapters[ind].updateVersion
                                        setWorkflow({...workflow})
                                    }}></Switch>
                                </Grid>
                                <Grid item style={{marginTop:5,marginBottom:15}}>
                                    <TextField label="Allowed users (comma separated)" variant="outlined"  onChange={(e)=>{chapter.allowedUsers=e.target.value.split(','); setWorkflow({...workflow})}} required size="small" value={chapter.allowedUsers.join(',')} style={{width:'400px'}}/>
                                </Grid>
                                <Grid item style={{marginTop:5,marginBottom:5}}>
                                    <TextField label="Chapter name" variant="outlined"  onChange={(e)=>{chapter.name=e.target.value; setWorkflow({...workflow})}} required size="small" value={chapter.name} style={{width:'400px'}}/>
                                </Grid>
                            {chapter.questions.length===0?
                            <Button size="small" variant="contained" color="primary" onClick={(e)=>{
                            chapter.questions=[{name:"",description:[],questionConfig:{type:"manual",pictureRequired:"no",subtype:undefined as never as string }} as QCquestion]
                            setWorkflow({...workflow})
                            }} style={{width:'200px'}}>Add question</Button>
                            :chapter.questions.map((que:QCquestion,index:number)=>{
                                
                            return <Accordion style={{width:"100%"}} key={index}>
                                <AccordionSummary style={{border:'2px solid #aaa',margin:"5px"}}>
                                    <Grid container direction="row" spacing={2} style={{ justifyContent:"space-between"}}>
                                        <Typography>{index +'. '+que.name}</Typography>
                                        <Grid item>
                                            <Button size="small" variant="contained" color="primary" onClick={(e)=>{
                                                e.stopPropagation()
                                                chapter.questions.splice(index,1)
                                                setWorkflow({...workflow})
                                                }
                                            } style={{width:'200px',margin:2}}>Delete question</Button>
                                            <Button size="small" variant="contained" color="primary" onClick={(e)=>{
                                                e.stopPropagation()
                                            chapter.questions=[...chapter.questions.slice(0,index+1),{name:"",description:[],questionConfig:{type:"manual",pictureRequired:"no",subtype:undefined as never as string }} as QCquestion,...chapter.questions.slice(index+1)]
                                            setWorkflow({...workflow})
                                            }} style={{width:'200px'}}>Add question ↓</Button>
                                        </Grid>

                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid container direction="column" spacing={2} style={{marginLeft:30,marginBottom:35,marginTop:15,padding:15, border:'2px solid #aaa', borderRadius:5,width:"80%"}}  >
                                <Grid item>
                                    <TextField label="Question Name" variant="outlined"  onChange={(e)=>{chapter.questions[index].name=e.target.value; setWorkflow({...workflow})}} required size="small" value={que.name} style={{width:'80%'}}/>
                                </Grid>
                                {que.description.map((desc,ind)=>{
                                return <Grid item container key={ind} style={{border:'2px solid grey', width:"90%",margin:'5px'}}> 
                                    <Grid item container style={{marginTop:"50px"}}>                                                           
                                            <Grid item><input
                                                style={{maxWidth:"400px"}}
                                                type="file"
                                                name="myImage"
                                                onChange={async (event) => {
                                                    if (event.target.files?.[0]){
                                                        const blob=new Blob([event.target.files?.[0]])
                                                        chapter.questions[index].description[ind].image=blob
                                                        setWorkflow({...workflow});}
                                                    }}
                                            /></Grid>
                                            {desc.image && typeof desc.image !=='string' &&  <Grid item><img src={URL.createObjectURL(desc.image as Blob)} alt="problems :(" style={{maxWidth:100}}></img></Grid>}
                                            
                                </Grid>
                                <Grid item style={{marginTop:"10px"}}>
                                <TextField label="Description text" variant="outlined"  onChange={(e)=>{chapter.questions[index].description[ind].text=e.target.value; setWorkflow({...workflow})}} required size="small" value={desc.text} />
                                    </Grid>
                                            <Button variant="contained" color="primary" onClick={()=>{chapter.questions[index].description.splice(ind,1);setWorkflow({...workflow})}}>Delete</Button>
                                        </Grid>
                                    })}
                                <Grid item style={{marginTop:5}}>
                                    <Button variant="contained" color="primary" onClick={()=>{chapter.questions[index].description.push({image:undefined as unknown as string,text:""});setWorkflow({...workflow})}} style={{width:'400px'}}>Add description</Button>
                                </Grid>
                                <Grid item>
                                    <FormControl size="small" style={{width:'400px'}}>
                                        <InputLabel id="select-label" style={{marginLeft:12,marginTop:-8}}>Question Type</InputLabel>
                                        <Select   labelId="select-label" label="Type" variant="outlined"  onChange={(e)=>{
                                            chapter.questions[index].robotCommand=undefined;
                                            chapter.questions[index].robotConnect=undefined
                                            chapter.questions[index].robotTest=undefined
                                            if (e.target.value==='test' || e.target.value==='command' || e.target.value==='connect')
                                            {
                                                chapter.questions[index].questionConfig.type="automatic"
                                                chapter.questions[index].questionConfig.subtype=e.target.value
                                                if (e.target.value==='command')
                                                {chapter.questions[index].robotCommand={commandType:"call_ros_service",expected:[],args:{version:1}}}
                                                if (e.target.value==='test')
                                                {chapter.questions[index].robotTest={args:{leftType:"db",leftValue:"",rightType:"value",rightValue:"",operator:"="}};}
                                                if (e.target.value==='connect')
                                                {chapter.questions[index].robotConnect={customer:0};}
                                            }   
                                            else
                                            {
                                                chapter.questions[index].questionConfig.type="manual"
                                            }

                                            
                                            setWorkflow({...workflow})}
                                            }  required  value={ que.questionConfig.type==='manual'?"manual":que.questionConfig.subtype} >
                                            <MenuItem value={'test'} >Database checkup</MenuItem>
                                            <MenuItem value={'command'}>Robot Command</MenuItem>
                                            <MenuItem value={'manual'} >Manual</MenuItem>
                                            <MenuItem value={'connect'} >Connect</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>

                                <InputLabel id="select-label5" style={{marginLeft:12,marginTop:0}}>Picture required</InputLabel>

                                <Select   labelId="select-label5" style={{width:'400px'}} label="Picture required" variant="outlined"  onChange={(e)=>{chapter.questions[index].questionConfig.pictureRequired=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.questionConfig.pictureRequired??""} >
                                                            <MenuItem value={'always'} >{'Always'}</MenuItem>
                                                            <MenuItem value={'if passed'} >{'If passed'}</MenuItem>
                                                            <MenuItem value={'if failed'} >{'If failed'}</MenuItem>
                                                            <MenuItem value={'optional'} >{'Optional'}</MenuItem>
                                                            <MenuItem value={'no'} >{'No'}</MenuItem>
                                                        </Select>
                                </Grid>
                                <Grid item container direction="row">
                                    {que.questionConfig.subtype==='test' && que.robotTest &&
                                    <Grid item style={{padding:10,margin:10,marginLeft:30, border:'2px solid grey', borderRadius:5}}>
                                        <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                            <InputLabel id="select-label1" style={{marginLeft:12,marginTop:-8}}>Table</InputLabel>
                                            <Select   labelId="select-label1" label="Table" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.leftValue=e.target.value+'.'+que.robotTest.args.leftValue.split(".")[1] as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.leftValue.split('.')[0]??""} >
                                                <MenuItem value={'latestdevice_data'} >Latest Device Data</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                            <InputLabel id="select-label2" style={{marginLeft:12,marginTop:-8}}>Key</InputLabel>
                                            <Select   labelId="select-label2" label="Key" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.leftValue=que.robotTest.args.leftValue.split(".")[0]+'.'+e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.leftValue.split('.')[1]??""} >
                                                {keys?.map((key,ind)=><MenuItem value={key} key={ind}>{key}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                            <InputLabel id="select-label3" style={{marginLeft:12,marginTop:-8}}>Operator</InputLabel>
                                            <Select   labelId="select-label3" label="Operator" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.operator=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.operator??""} >
                                                <MenuItem value={'='} >=</MenuItem>
                                                <MenuItem value={'>'} >{'>'}</MenuItem>
                                                <MenuItem value={'<'} >{"<"}</MenuItem>
                                                <MenuItem value={'>='} >{">="}</MenuItem>
                                                <MenuItem value={'<='} >{"<="}</MenuItem>
                                                <MenuItem value={'<>'} >!=</MenuItem>
                                                <MenuItem value={'range'} >Range(space separated)</MenuItem>
                                                <MenuItem value={'includes'} >Includes (SQL LIKE)</MenuItem>
                                                <MenuItem value={'not_includes'} >Not Includes (SQL NOT LIKE)</MenuItem>
                                                <MenuItem value={'oneof'} >Equals to one of array (comma separated,no spaces)</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                            <InputLabel id="select-label4" style={{marginLeft:12,marginTop:-8}}>Value type</InputLabel>
                                            <Select   labelId="select-label4" label="Value type" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.rightType=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.rightType??""} >
                                                <MenuItem value={'value'} >{'Value'}</MenuItem>
                                                <MenuItem value={'db'} >{'Database'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {que.robotTest.args.rightType==='value'&&
                                            <TextField label="Value" type="text" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.rightValue=e.target.value; setWorkflow({...workflow})}} required size="small" value={que.robotTest.args.rightValue} style={{width:'100%',marginTop:10}}/>
                                        }
                                        {que.robotTest.args.rightType==='db' &&
                                            <Grid item>
                                                <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                                    <InputLabel id="select-label1" style={{marginLeft:12,marginTop:-8}}>Table</InputLabel>
                                                    <Select   labelId="select-label1" label="Table" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.rightTable=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.rightTable??""} >
                                                        <MenuItem value={'latest'} >Latest Device Data</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl size="small" style={{width:'100%',marginTop:10}}>
                                                    <InputLabel id="select-label2" style={{marginLeft:12,marginTop:-8}}>Key</InputLabel>
                                                    <Select   labelId="select-label2" label="Key" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotTest.args.rightKey=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotTest.args.rightKey??""} >
                                                        {keys?.map((key,ind)=><MenuItem value={key} key={ind}>{key}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>}
                                    {que.questionConfig.subtype==='command' && que.robotCommand && 
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <TextField label="Robot Command" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.commandType=e.target.value; setWorkflow({...workflow})}}  required size="small" value={que.robotCommand.commandType} style={{width:'400px'}}/>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1} >
                                        
                                            <Grid item container spacing={1}>
                                                <Grid item>
                                                    <TextField label="Version" variant="outlined" type="number" onChange={(e)=>{chapter.questions[index].robotCommand.args.version=Number(e.target.value); setWorkflow({...workflow})}} required size="small" value={que.robotCommand.args.version} style={{width:'100px'}}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextField label="Service args for Command" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.args.service_args=e.target.value; setWorkflow({...workflow})}} required size="small" value={que.robotCommand.args.service_args} style={{width:'796px'}}/>
                                                </Grid>
                                                {que.robotCommand.commandType==='remove_wifi' && <Grid item>
                                                <TextField label="Ssid" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.args.ssid=e.target.value; setWorkflow({...workflow})}} required size="small" value={que.robotCommand.args.ssid??''} style={{width:'796px'}}/>      
                                                    </Grid>}
                                                <Grid item>
                                                    <TextField label="Service namespace for Command" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.args.service_namespace=e.target.value; setWorkflow({...workflow})}} required size="small" value={que.robotCommand.args.service_namespace} style={{width:'296px'}}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1} >
                                            {que.robotCommand.expected.map((arg:any,ind:number)=>{
                                                return <Grid item container  key={ind} spacing={1} style={{marginTop:"15px"}}>
                                                    <FormControl size="small" style={{width:'396px'}}>
                                                        <InputLabel id="select-label4" style={{marginLeft:12,marginTop:-8}}>Type</InputLabel>
                                                        <Select   labelId="select-label4" label="Expected type" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.expected[ind].type=e.target.value as string; setWorkflow({...workflow})}}  required  value={que.robotCommand.expected[ind].type??""} >
                                                            <MenuItem value={'value_exact'} >{"result[key]=value"}</MenuItem>
                                                            <MenuItem value={'value_range'} >{'result[key] is in range (space separated)'}</MenuItem>
                                                            <MenuItem value={'value_in_array'} >{'result[key] is in [2,3,4,5]'}</MenuItem>
                                                            <MenuItem value={'array_range'} >{'result[key].every is in range (space separated)'}</MenuItem>
                                                            <MenuItem value={'array_includes'} >{'result[key] is array and includes value'}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Grid item>
                                                        <TextField label="Expected Key" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.expected[ind].key=e.target.value; setWorkflow({...workflow})}} required size="small" value={arg.key} style={{width:'496px'}}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField label="Expected Value" variant="outlined"  onChange={(e)=>{chapter.questions[index].robotCommand.expected[ind].value=e.target.value; setWorkflow({...workflow})}} required size="small" value={arg.value} style={{width:'596px'}}/>
                                                    </Grid>
                                                <a href="https://docs.google.com/document/d/1qcGfc5QJu-H-aNptMEB2R-aM-ZKAw73uz30fJ6jJDhE/edit?usp=sharing">Documentation</a>
                                                </Grid>
                                            })}
                                            <Button variant="contained" color="primary" onClick={()=>{
                            chapter.questions[index].robotCommand.expected.push({type:"",key:"",value:""})
                            setWorkflow({...workflow})
                            }} style={{width:'400px'}}>Add expected</Button>
                                        </Grid>
                                    </Grid>}
                                    {que.questionConfig.subtype==='connect' && que.robotConnect &&
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <AutocompleteV2 options={customers} value={customers.find((c)=>c.id===que.robotConnect.customer)} getOptionLabel={(c:any)=>c.id+" "+c.name} renderOption={(c:any)=>{return <div>{c.id+" "+c.name}</div>}} getOptionSelected={(a:any,b:any)=>a.id===b.id}
                                            renderInput={
                                                params => 
                                                { 
                                                return <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    label="Choose customer"
                                                />}
                                            } onChange={(e,val)=>{
                                                que.robotConnect.customer=val.id
                                                setWorkflow({...workflow})
                                            }} style={{width:"400px"}}></AutocompleteV2>

                                        </Grid>
                                    </Grid>   
                                    } 
                                </Grid>
                            </Grid>   
                            </AccordionDetails> 
                        </Accordion>

                            
                        })}
                        
                        </AccordionDetails>
                        </Accordion>
                    </Grid>
                    
                   
                </Grid>)}
                </Grid>
                )}
                 <Grid item container style={{margin:10}}>
                    <Button variant="contained" color="primary" size="large" style={{width:'400px',margin:5}} onClick={(e)=>submit(e)} disabled={uploading || !workflow} type="submit">
                        Submit
                    </Button>
                    <Button variant="contained" color="primary" size="large" style={{width:'400px',margin:5}} onClick={(e)=>setWorkflow({...workflow,name:workflow.name+"Copy",version:"1",id:(workflows.reduce((acc:number,it:any)=>{ return Number(it.id)>acc? Number(it.id):acc },0)+1).toString()})} disabled={uploading || !workflow}>
                        Create a copy
                    </Button>
                        <Button variant="contained" color="primary" size="large" style={{width:'400px',margin:5}} onClick={(e)=>{setWorkflow({id:(workflows.reduce((acc:number,it:any)=>{ return Number(it.id)>acc? Number(it.id):acc },0)+1).toString(),version:"1",name:"",chapters:[],filter:{serialNumberFilterRange:[{start:"1",end:"1"}]}})}} disabled={uploading || !workflows}>
                           Create Workflow </Button>
                </Grid>
                </Grid>
                </Grid>
            </form>
        </Grid>)
    
}