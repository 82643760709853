import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Customer } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import { customersArraySelector, dealersSelector, customersTreeSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import {  Checkbox, Divider, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";


export interface Layout {
    id: number;
    originalId: number;
    name: string;
    template: string;
    tablet: number;
}


type MoveOrCopyCustomerLayoutsInput = {
    fromCustomer: Customer | null;
    toCustomer: Customer | null;
    ids: Layout[];
    move: boolean;
};

function transferCustomerLayouts(input: MoveOrCopyCustomerLayoutsInput) {
    return ConfigurationsService.transferCustomerLayouts(input.fromCustomer!.id, input.toCustomer!.id, input.ids.map(l =>l.originalId), input.move).then(() => input);
}

export default function MoveOrCopyCustomerLayoutsWidget({ widgetName ,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<MoveOrCopyCustomerLayoutsInput>({
        defaultValues: { fromCustomer: null, toCustomer: null, ids: [], move: false },
    });

    const { displayToast } = useToast();

    const fromCustomer = watch("fromCustomer");
    const toCustomer = watch("toCustomer");
    const layouts = watch("ids");
    const move = watch("move");

    const [dealer, setDealer] = useState(null as Customer | null);

    const [fromLayouts, setFromLayouts] = useState([] as Layout[]);

    const customers = useSelector(customersArraySelector);
    const tree = useSelector(customersTreeSelector);
    
    const dealers : Customer[] = useSelector(dealersSelector);

    const [customersList, setCustomerList] = useState([] as Customer[]);
       
    useEffect(() => {
        register("fromCustomer", defaultRules);
        register("toCustomer", defaultRules);
        register("ids", {required: "Select at leat 1 layout", validate: (value) => {
            if(value.length === 0)
                return "Select at leat 1 layout";
            else
                return true;
        }});
        register("move");
    }, [register]);

    const onComplete = useCallback(
        (input: MoveOrCopyCustomerLayoutsInput) => {
            reset();
            setDealer(null);
            displayToast({
                message: `Successfully ${move ? "moved": "copied"} ${layouts.length} layouts from ${
                    input.fromCustomer!.name
                } to ${input.toCustomer!.name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [reset, displayToast, move, layouts.length]
    );
    
    const { exec: updateCustomerLayout, pending: transferingLayouts, error } = useAsync(
        transferCustomerLayouts,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const onSelectFromCustomer = useCallback(
        async () =>{
            setValue("ids", [])
            if(fromCustomer !== null){
                const layouts = await ConfigurationsService.getCustomerLayouts(fromCustomer.id);
                setFromLayouts(layouts); 
            }
            else{
                return Promise.resolve(()=> setFromLayouts([]));
            }
        },[fromCustomer, setValue]
    );

    const { exec: fetchCustomerLayouts, pending: fetchingLayouts, error: fetchingError } = useAsync(
        onSelectFromCustomer,
        {
            immediate: false,
        }
    );
    useEffect(() => {
        if(dealers.length === 1){
            setDealer(dealers[0])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const loading = transferingLayouts || customers.length === 0 || dealers.length === 0;
    
    useEffect(() => {
        if(dealer !== null) {
            setCustomerList(tree?.find(c => c.id === dealer.id)?.toArray().sort((c1,c2) => c1.id-c2.id) || []);
        }

    },[tree, dealer]);

    useEffect(() => {
        fetchCustomerLayouts();
    },[fetchCustomerLayouts, fromCustomer]);

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Copy/Move customer layouts"
            subTitle="Copy or move layouts from one customer to another."
            onSubmit={handleSubmit(updateCustomerLayout)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            
            <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
                
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    value={move}
                    exclusive
                    disabled={loading}
                    onChange={(ev, newVal) => {
                        if(newVal !== null)
                            setValue("move", newVal);
                    }}
                >
                    <ToggleButton  value={false}>{<b>Copy</b>}</ToggleButton>
                    <ToggleButton value={true}>{<b>Move</b>}</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <AutocompleteV2
                selectOnTab
                value={dealer}
                disabled={loading  || fetchingLayouts}
                options={dealers?.filter(d => d.id !== 1)}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such dealer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label={"Dealer"}
                            placeholder="Choose a dealer"
                            required
                            
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setDealer(val);
                    setValue("fromCustomer",null);
                    setValue("toCustomer",null);
                }}
            />
            <Divider  style={{marginTop:"7px", marginBottom:"7px"}}/>
            <AutocompleteV2
                selectOnTab
                value={fromCustomer}
                disabled={loading || dealer === null  || fetchingLayouts}
                options={customersList}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such customer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Source customer"
                            placeholder="Choose a customer"
                            required
                            {...getErrorProps(errors as any, "fromCustomer")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    if(val?.id === toCustomer?.id)
                        setValue("toCustomer", null);
                    setValue("fromCustomer", val);
                }}
            />
            <AutocompleteV2
                selectOnTab
                value={toCustomer}
                disabled={loading || dealer === null}
                options={customersList.filter(c => c.id !== fromCustomer?.id)}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such customer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Target customer"
                            placeholder="Choose a customer"
                            required
                            {...getErrorProps(errors as any, "toCustomer")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("toCustomer", val);
                }}
            />
            <Divider  style={{marginTop:"7px", marginBottom:"7px"}}/>
            <div>
                <AutocompleteV2
                    //selectOnTab
                    multiple
                    disableCloseOnSelect
                    limitTags={5}
                    groupBy={(option) => option.template}
                    renderGroup={(props) => {
                        
                        return <div key={props.group}>
                        <Checkbox
                            color="primary"
                            style={{ marginRight: 8 }}
                            checked={layouts.filter(l => l.template === props.group).length === fromLayouts.filter(l => l.template === props.group).length}
                            indeterminate={layouts.filter(l => l.template === props.group).length > 0 && layouts.filter(l => l.template === props.group).length < fromLayouts.filter(l => l.template === props.group).length}
                            onChange={(ev) => {
                                if(ev.target.checked){
                                    const toAdd = fromLayouts.filter(l => l.template === props.group && !layouts.includes(l));
                                    setValue("ids", layouts.concat(toAdd));
                                }
                                else{
                                    setValue("ids", layouts.filter(l => l.template !== props.group));
                                }
                            }}
                        />
                        <Typography color="primary" component="span">{props.group}</Typography>
                        {props.children}
                    </div>
                    }}
                    value={layouts}
                    disabled={loading || fromCustomer === null  || fetchingLayouts}
                    options={fromLayouts}
                    getOptionLabel={(r) => r.name}
                    renderOption={(option, props) => {
                        return (
                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <Checkbox
                            size = "small"
                                style={{ marginRight: 8 }}
                                checked={props.selected}
                            />
                            {option.name}
                        </div>
                        );
                    }}
                    getOptionSelected={(a, b) => a.id === b.id}
                    noOptionsText="No such layout"
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label="Layouts"
                                placeholder="Choose layouts"
                                required
                                {...getErrorProps(errors as any, "ids")}
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setValue("ids", val);
                    }}
                />
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        size="small"
                        color="primary"
                        disabled={loading || fromLayouts.length === 0 || fetchingLayouts}
                        checked={fromLayouts.length === layouts.length && fromLayouts.length > 0}
                        indeterminate={layouts.length > 0 && layouts.length < fromLayouts.length}
                        onChange={(e) => {
                            if(e.target.checked){
                                setValue("ids", fromLayouts);
                            }
                            else{
                                setValue("ids", []);
                            }
                        }}
                        tabIndex={-1}
                    />
                    <label>
                        {"Select all"}
                    </label>
                </div>
                <Typography variant="body1"  color="textSecondary">Total Layouts: {fetchingLayouts ? "loading..." : fromLayouts.length}</Typography>
                <Typography variant="body1"  color="textSecondary">Selected Layouts: {layouts.length}</Typography>
                {fetchingError && <Typography variant="body1"  color="error">{fetchingError?.message}</Typography>}
            </div>
           
            
        </BaseWidget>
    );
}
