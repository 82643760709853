import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LogIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SupportIcon from "@material-ui/icons/Help";
import HistoryIcon from '@mui/icons-material/History';
import ProductionIcon from '@mui/icons-material/Handyman';
import FilesIcon from "@material-ui/icons/Description";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import TimezoneIcon from "@material-ui/icons/AvTimer";
import SettingsIcon from "@material-ui/icons/Settings";
import DeveloperIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import RobotIcon from "../components/RobotIcon";
import BaseStationIcon from "@material-ui/icons/SettingsRemote";
import CustomersIcon from "@material-ui/icons/People";
import MapIcon from "@material-ui/icons/Map";
import QuoteIcon from "@material-ui/icons/Gavel";
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import ChangelogIcon from "@material-ui/icons/LowPriority";
import NavListItem, { NavListItemProps } from "../components/NavListItem";
import AddchartIcon from '@mui/icons-material/Addchart';
import ConnectedIcon from "../components/ConnectedIcon";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import tmrLogoBlue from "../TinyMobileRobots_280x32_72dpi.png";
import tmrLogoWhite from "../tmr.png";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage, usePrevious } from "../hooks";
import { signOut } from "../redux/auth/actions";
import { isAuthenticatedSelector, userAccessSelector } from "../redux/auth/selectors";
import { locationSelector } from "../redux/router/selectors";
import { basestationsSelector, connectionStateSelector, customersSelector, excludeDeprecatedSelector,  robotsSelector, tabletsSelector, timezoneSelector } from "../redux/app/selectors";
import { Button, FormControlLabel, MenuItem, Popper, Switch, TextField } from "@material-ui/core";
import { push } from "connected-react-router";
import { userSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { setExcludeDeprecated, setTimezone } from "src/redux/app/actions";
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { disclaimer } from "src/components/PrivateRoute";
import useDialog from "src/hooks/useDialog";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const useStyles = makeStyles((theme: Theme) => ({
    root: { display: "flex"},
    drawerDrawer: {
        zIndex: `${theme.zIndex.drawer} !important` as any,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: (props: { hideDrawer: boolean }) => ({
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        display: props.hideDrawer ? "none" : undefined,
    }),
    connectedIcon: {
        marginLeft: "auto",
        "& > :last-child": {
            display: "none",
        },
    },
    connectedIconAbs: {
        paddingLeft: theme.spacing(2),
        marginTop: "auto",
    },
    drawerPaper: {
        width: theme.tmrOptions.drawerWidth,
        paddingRight:"2px"
    },
    navLink: {
        whiteSpace: "nowrap",
        color: theme.palette.common.black,
    },
    imageContainer: {
        ...theme.mixins.toolbar,
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > :first-child": {
            margin: "auto",
            padding: 2,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img": {
                width: "95%",
            },
        },
        "& > :last-child": {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: -theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(0),
            fontSize: theme.typography.pxToRem(17),
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.tmrPalette.grey,
        },
        "& > *": {
            width: "90%",
        },
    },
}));

const links: (NavListItemProps & { accessField?: string, accessFieldSubstr?: string,hidden?: boolean, disabledIfNotVerified?: boolean })[][] = [
    [
        {
            text: "Home",
            to: "/home",
            activeOnlyWhenExact: true,
            TextIcon: HomeIcon,
        },
        {
            text: "Dealer Materials",
            to: "/dealermaterials",
            activeOnlyWhenExact: true,
            TextIcon: FilesIcon,
            accessField: "DealerMaterials"
        },
        {
            text: "Changelog",
            to: "/changelog",
            activeOnlyWhenExact: true,
            TextIcon: ChangelogIcon,
        },
        {
            text: "Quote Generator",
            to: "/quotegenerator",
            TextIcon: QuoteIcon,
            accessField: "QuoteGenerator",
        },
        {
            text: "Savings Calculator",
            to: "/savings",
            TextIcon: AddchartIcon,
            accessField:"SavingsCalculator"
        }
    ],
    [
        {
            text: "Robots",
            to: "/devices/robots",
            TextIcon: RobotIcon,
        },
        {
            text: "Tablets",
            to: "/devices/tablets",
            TextIcon: TabletAndroidIcon,
        },
        {
            text: "Reference Stations",
            to: "/devices/referencestations",
            TextIcon: BaseStationIcon,
        },
        {
            text: "Customer Tree",
            to: "/customers",
            TextIcon: CustomersIcon,
        },
        {
            text: "Map",
            to: "/map",
            TextIcon: MapIcon,
            clearQuery: true,
        },
        {
            text: "Production",
            to: "/production",
            TextIcon: ProductionIcon,
            accessField: "Production",
        },
        {
            text: "History",
            to: "/history",
            accessField: 'History',
            TextIcon: HistoryIcon,
        },
        {
            text: "Support",
            to: "/support",
            inactiveOn: ["/support/legacy"],
            TextIcon: SupportIcon,
        },
        {
            text: "Database Tool",
            to: "/dbtool",
            TextIcon: StorageIcon,
            accessFieldSubstr: "DbTool"
        },
        {
            text: "QC App",
            to: "/qcapp",
            TextIcon: PhonelinkSetupIcon,
            accessField: "QCApp"
        }
    ],
    [
        {
            text: "Commands",
            to:"/commands",
            TextIcon: BuildIcon,
            accessField:"BulkCommands"
        },
        {
            text: "Control Panel",
            to: "/settings/user",
            TextIcon: SettingsIcon,
        },
    ],
    [
        {
            text: "My Account",
            to: "/account/me",
            TextIcon: AccountCircleIcon,
            disabledIfNotVerified: false,
        },
        {
            text: "Manage Accounts",
            to: "/accounts",
            TextIcon: AccountTreeIcon,
            accessField: 'User',
        },
        {
            text: "User Actions Log",
            to: "/administration/log",
            TextIcon: LogIcon,
            accessField: 'UserActions',
        },
    ],
];

export default React.memo(function Header() {
    /* Hide the drawer when signing in */
    const isSignedIn = useSelector(isAuthenticatedSelector);
    const isVerified = useSelector(userSelector).verified ?? true;
    const path = window.location.pathname;

    const timezone = useSelector(timezoneSelector);
    const excludeDeprecated = useSelector(excludeDeprecatedSelector);
    const [excludeDeprecatedLocalStorage, setExcludeDeprecatedLocalStorage] = useLocalStorage("excludeDeprecated", true);
    const hideDrawer = !isSignedIn || path.includes("forgotpassword") || path.includes("resetpassword") || path.includes("verifyemail");

    const userAccess=useSelector(userAccessSelector)
    const classes = useStyles({ hideDrawer });

    const dispatch = useDispatch();
    const theme = useTheme();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSignout = () => dispatch(signOut());

    const { pathname } = useSelector(locationSelector);
    const previousPathname = usePrevious(pathname) || pathname;

    useEffect(() => {
        if (previousPathname !== pathname) {
            setMobileOpen(false);
        }
    }, [pathname, previousPathname]);

    const robots = useSelector(robotsSelector);
    const tablets = useSelector(tabletsSelector);
    const basestations = useSelector(basestationsSelector);
    const customers = useSelector(customersSelector);

    const {displayDialog}=useDialog()

    const drawer = links.filter(section => !(section.some(item => item.to === "/settings/user") && userAccess.length === 1))
    let drawerContent = drawer.map((section, idx) => {
        const isSecondSection = idx === 1;
        const isLastSection = idx === drawer.length - 1;
        return (
            <List key={idx}>
                {isSecondSection && (
                    <ListItem key="robot-quick-search" style={{paddingLeft: "7px", paddingRight: "7px"}}>
                        <AutocompleteV2
                            PopperComponent={(props) => <Popper {...props} placement="right-end"/>}
                            disableClearable
                            clearOnSelectAndBlur
                            fullWidth
                            options={robots}
                            getOptionLabel={(r) => {
                                const customer = customers.dto[r.customerId ?? -1];
                                let ret = "("+r.id+") "+r.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+r.customerId+"]";
                                return ret;
                            }}
                            noOptionsText="No such robot"
                            onChange={(_e, val) => {
                                if (val !== undefined && val !== null)
                                    dispatch(push("/devices/robots/"+val.id));
                            }}
                            renderInput={params => 
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Robot quick-search"
                                />
                            }
                            getOptionSelected={(a, b) => a.id === b.id}
                            renderOption={(r) => {
                                const customer = customers.dto[r.customerId ?? -1];
                                let ret = "("+r.id+") "+r.name;
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+r.customerId+"]";
                                if (r.deprecated !== undefined && r.deprecated !== null)
                                    return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                                return (<div>{ret}</div>);
                            }}
                            disabled={!isVerified}
                            loading={isVerified && robots.length === 0}
                            
                        />
                    </ListItem>
                )}
                {isSecondSection && (
                    <ListItem key="tablet-quick-search" style={{paddingLeft: "7px", paddingRight: "7px", marginTop: "-15px"}}>
                        <AutocompleteV2
                            PopperComponent={(props) => <Popper {...props} placement="right-end"/>}
                            disableClearable
                            clearOnSelectAndBlur
                            fullWidth
                            options={tablets}
                            getOptionLabel={(t) => {
                                const customer = customers.dto[t.customerId ?? -1];
                                let ret = t.id+" ";
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                return ret;
                            }}
                            noOptionsText="No such tablet"
                            onChange={(_e, val) => {
                                if (val !== undefined && val !== null)
                                    if (val.connectedRobotId !== undefined && val.connectedRobotId !== null)
                                        dispatch(push("/devices/robots/"+val.connectedRobotId+"?tablet="+val.id));
                                    else
                                        dispatch(push("/devices/tablets/"+val.id));
                            }}
                            renderInput={params => 
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Tablet quick-search"
                                />
                            }
                            getOptionSelected={(a, b) => a.id === b.id}
                            renderOption={(t) => {
                                const customer = customers.dto[t.customerId ?? -1];
                                let ret = t.id+"";
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+t.customerId+"]";
                                if (t.deprecated !== undefined && t.deprecated !== null)
                                    return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                                return (<div>{ret}</div>);
                            }}
                            disabled={!isVerified}
                            loading={isVerified && robots.length === 0}

                        />
                    </ListItem>
                )}
                {isSecondSection && (
                    <ListItem key="basestation-quick-search" style={{paddingLeft: "7px", paddingRight: "7px", marginTop: "-15px", marginBottom: "5px"}}>
                        <AutocompleteV2
                            PopperComponent={(props) => <Popper {...props} placement="right-end"/>}
                            disableClearable
                            clearOnSelectAndBlur
                            fullWidth
                            options={basestations}
                            getOptionLabel={(r) => {
                                const customer = customers.dto[r.customerId ?? -1];
                                let ret = r.id+"";
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+r.customerId+"]";
                                return ret;
                            }}
                            noOptionsText="No such reference station"
                            onChange={(_e, val) => {
                                if (val !== undefined && val !== null)
                                    dispatch(push("/devices/referencestations/"+val.id));
                            }}
                            renderInput={params => 
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Reference Station"
                                />
                            }
                            getOptionSelected={(a, b) => a.id === b.id}
                            renderOption={(r) => {
                                const customer = customers.dto[r.customerId ?? -1];
                                let ret = r.id+"";
                                if (customer !== undefined)
                                    ret += " ["+customer.name+"]";
                                else
                                    ret += " ["+r.customerId+"]";
                                if (r.deprecated !== undefined && r.deprecated !== null)
                                    return (<div style={{textDecoration: "line-through", color: "#555555"}}>{ret}</div>);
                                return (<div>{ret}</div>);
                            }}
                            disabled={!isVerified}
                            loading={isVerified && robots.length === 0 && tablets.length === 0 && basestations.length === 0}
                            
                        />
                    </ListItem>
                )}
                {section.map(({accessField, accessFieldSubstr, hidden, disabledIfNotVerified, ...props }) => {
                    return (accessField ? !userAccess.includes(accessField):false) || (accessFieldSubstr ? !userAccess.some(str => str.includes(accessFieldSubstr)): false) || hidden ? null : (
                        <NavListItem                        
                            key={props.to}
                            
                            {...props}
                            textProps={{
                                className: classes.navLink,
                            }}
                            disabled={(disabledIfNotVerified === undefined || disabledIfNotVerified) && !isVerified}
                        />
                    );
                })}
                
                {isLastSection && (
                    <ListItem key="timezone-select">
                        <ListItemIcon>
                            <TimezoneIcon />
                        </ListItemIcon>
                        <TextField
                            label="UTC Time Offset"
                            margin="dense"
                            size="small"
                            select
                            fullWidth
                            value={timezone}
                            onChange={(ev: any) => dispatch(setTimezone({timezone: ev.target.value}))}
                            variant="outlined"
                        >
                            <MenuItem key={-12} value={-12}>UTC -12</MenuItem>
                            <MenuItem key={-11} value={-11}>UTC -11</MenuItem>
                            <MenuItem key={-10} value={-10}>UTC -10</MenuItem>
                            <MenuItem key={-9} value={-9}>UTC -9</MenuItem>
                            <MenuItem key={-8} value={-8}>UTC -8</MenuItem>
                            <MenuItem key={-7} value={-7}>UTC -7</MenuItem>
                            <MenuItem key={-6} value={-6}>UTC -6</MenuItem>
                            <MenuItem key={-5} value={-5}>UTC -5</MenuItem>
                            <MenuItem key={-4} value={-4}>UTC -4</MenuItem>
                            <MenuItem key={-3} value={-3}>UTC -3</MenuItem>
                            <MenuItem key={-2} value={-2}>UTC -2</MenuItem>
                            <MenuItem key={-1} value={-1}>UTC -1</MenuItem>
                            <MenuItem key={0} value={-0}>UTC 0</MenuItem>
                            <MenuItem key={1} value={1}>UTC +1</MenuItem>
                            <MenuItem key={2} value={2}>UTC +2</MenuItem>
                            <MenuItem key={3} value={3}>UTC +3</MenuItem>
                            <MenuItem key={4} value={4}>UTC +4</MenuItem>
                            <MenuItem key={5} value={5}>UTC +5</MenuItem>
                            <MenuItem key={6} value={6}>UTC +6</MenuItem>
                            <MenuItem key={7} value={7}>UTC +7</MenuItem>
                            <MenuItem key={8} value={8}>UTC +8</MenuItem>
                            <MenuItem key={9} value={9}>UTC +9</MenuItem>
                            <MenuItem key={10} value={10}>UTC +10</MenuItem>
                            <MenuItem key={11} value={11}>UTC +11</MenuItem>
                            <MenuItem key={12} value={12}>UTC +12</MenuItem>
                        </TextField>
                    </ListItem>
                )}
                {isLastSection && (
                    <ListItem key="sign-out-nav" button onClick={handleSignout} style={{marginTop: "30px"}}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText>Sign Out</ListItemText>
                    </ListItem>
                )}
                {(isLastSection && userAccess.includes('Developer')) && (
                    <NavListItem
                        key={"/developer"}
                        to="/developer"
                        text="Developer"
                        TextIcon={DeveloperIcon}
                        textProps={{
                            className: classes.navLink,
                        }}
                        disabled={!isVerified}
                        clearQuery={true}
                    />
                )}
                {(isLastSection && userAccess.includes('DataExtractorRead')) && (
                    <NavListItem
                        key={"/data-extractor"}
                        to="/data-extractor"
                        text="Data Extractor"
                        TextIcon={DatasetLinkedIcon}
                        textProps={{
                            className: classes.navLink,
                        }}
                        disabled={!isVerified}
                        clearQuery={true}
                    />
                )}
            </List>
        );
    });

    drawerContent.unshift(<List key="-1">
        <ListItem key="exclude-deprecated">
            <FormControlLabel
                control={
                    <Switch
                        size="small"
                        checked={excludeDeprecated}
                        onChange={(e) => {
                            dispatch(setExcludeDeprecated({excludeDeprecated: e.currentTarget.checked}))
                            setExcludeDeprecatedLocalStorage(e.currentTarget.checked);
                        }}
                    />
                }
                label={<div style={{fontSize:"13px"}}>Exclude Deprecated Devices</div>}
            />
        </ListItem>
    </List>)

    drawerContent = React.Children.map(drawerContent, (c, i) => (
        <>
            {c}
            {i !== drawerContent.length - 1 && <Divider />}
        </>
    ));

    const connectionState = useSelector(connectionStateSelector)
        .connectionState;

    return (
        <div className={classes.root}>
            {!hideDrawer && (
                <>
                    <nav aria-label="navigation links">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden mdUp implementation="css">
                            <AppBar position="fixed" className={classes.appBar}>
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        className={classes.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <img src={tmrLogoWhite} alt="Logo" />
                                    {isSignedIn && (
                                        <ConnectedIcon
                                            className={classes.connectedIcon}
                                            state={connectionState}
                                        />
                                    )}
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                variant="temporary"
                                anchor={
                                    theme.direction === "rtl" ? "right" : "left"
                                }
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                className={classes.drawerDrawer}
                            >
                                <Toolbar />
                                {drawerContent}
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                <div className={classes.imageContainer}>
                                    <div>
                                        <img src={tmrLogoBlue} alt="Logo" />
                                    </div>
                                    <Typography>Service Center</Typography>
                                </div>
                                <Divider />
                                {drawerContent}
                                <div style={{paddingLeft:13,paddingTop:15}}><Button style={{textTransform:"none"}}startIcon={<GppMaybeIcon style={{fontSize:18,marginRight:-4}}/>} className= {classes.navLink} onClick={()=>displayDialog({dialogText:disclaimer,positiveButtonText:"agree",negativeButtonText:""})}>Disclaimer</Button></div>
                                <ConnectedIcon
                                    state={connectionState}
                                    className={classes.connectedIconAbs}
                                />
                            </Drawer>
                        </Hidden>
                    </nav>
                    <Hidden mdUp implementation="css">
                        <Toolbar />
                    </Hidden>
                </>
            )}
        </div>
    );
});
