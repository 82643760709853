import RobotNameWidget from "./RobotNameWidget";
import ConnectWidget from "./ConnectWidget";
import SetTabletUserLevelWidget from "./SetTabletUserLevelWidget";
import CustomerNameWidget from "./CustomerNameWidget";
import DeleteCustomerWidget from "./DeleteCustomerWidget";
import SetTabletAppVersionWidget from "./SetTabletAppVersionWidget";
import DeprecateDeviceWidget from "./DeprecateDeviceWidget";
import SalesRepWidget from "./SalesRepWidget";
import BaseStationWidget from "./BaseStationWidget";
import CustomerParentWidget from "./CustomerParentWidget";
import VoucherCheckerWidget from "./VoucherCheckerWidget";
import CreateCustomerWidget from "./CreateCustomerWidget";
import LockRSUpdateWidget from "./LockRSUpdateWidget";
import CreateGroupWidget from "./CreateGroupWidget";
import EmergencyEmailWidget from "./EmergencyEmail";
import WarrantySubscriptionWidget from "./WarrantySubscriptionWidget";
import ApproveCompanyWidget from "./ApproveCompanyWidget";
import BreakdownWidget from "./BreakdownWidget";
import SendTabletNotificationWidget from "./SendTabletNotification";
import LockTabletWidget from "./LockTablet";
import UpdateCustomerWidget from "./UpdateCustomer";
import MoveOrCopyCustomerLayoutsWidget from "./MoveOrCopyCustomerLayouts";
import RestoreCustomerLayoutsWidget from "./RestoreCustomerLayouts";
//import TriggerBreakdownWidget from "./TriggerBreakdownWidget";


export type Widget = ((props: { widgetName: string }) => JSX.Element) & {
    nameGiven: string;
    category?: string;
    requiredAccess?: string;
    favourite?: boolean;
};

//SharedHeightProvider bug workaround (function names can not be used in production build)
const ConnectWidgetExt = ConnectWidget as Widget;
ConnectWidgetExt.nameGiven = "ConnectWidget";
ConnectWidgetExt.category = 'Device';
ConnectWidgetExt.requiredAccess = 'ConnectDevices';

const RobotNameWidgetExt = RobotNameWidget as Widget;
RobotNameWidgetExt.nameGiven = "RobotNameWidget";
RobotNameWidgetExt.category='Device';
RobotNameWidgetExt.requiredAccess='RobotName';


const SetTabletUserLevelWidgetExt = SetTabletUserLevelWidget as Widget;
SetTabletUserLevelWidgetExt.nameGiven = "SetTabletUserLevelWidget";
SetTabletUserLevelWidgetExt.category='Device'
SetTabletUserLevelWidgetExt.requiredAccess='SetTabletUserLevel'

const CreateCustomerWidgetExt = CreateCustomerWidget as Widget;
CreateCustomerWidgetExt.nameGiven = "CreateCustomerWidget";
CreateCustomerWidgetExt.category='Customer'
CreateCustomerWidgetExt.requiredAccess = 'CreateCustomer';

const UpdateCustomerAGsWidgetExt = UpdateCustomerWidget as Widget;
UpdateCustomerAGsWidgetExt.nameGiven = "UpdateCustomerWidget";
UpdateCustomerAGsWidgetExt.category='Customer'
UpdateCustomerAGsWidgetExt.requiredAccess = 'UpdateCustomerAccessGroups';

const CustomerNameWidgetExt = CustomerNameWidget as Widget;
CustomerNameWidgetExt.nameGiven = "CustomerNameWidget";
CustomerNameWidgetExt.category = "Customer"
CustomerNameWidgetExt.requiredAccess = 'CustomerName';

const DeleteCustomerWidgetExt = DeleteCustomerWidget as Widget;
DeleteCustomerWidgetExt.nameGiven = "DeleteCustomerWidget";
DeleteCustomerWidgetExt.requiredAccess = 'DeleteCustomer';
DeleteCustomerWidgetExt.category='Customer'

const SetTabletAppVersionWidgetExt = SetTabletAppVersionWidget as Widget;
SetTabletAppVersionWidgetExt.nameGiven = "SetTabletAppVersionWidget";
SetTabletAppVersionWidgetExt.requiredAccess = 'TabletAppVersion';
SetTabletAppVersionWidgetExt.category = 'Device'

const DeprecateDeviceWidgetExt = DeprecateDeviceWidget as Widget;
DeprecateDeviceWidgetExt.nameGiven = "DeprecateDeviceWidget";
DeprecateDeviceWidgetExt.requiredAccess = "DeprecateDevice";
DeprecateDeviceWidgetExt.category='Device'

const SalesRepWidgetExt = SalesRepWidget as Widget;
SalesRepWidgetExt.nameGiven = "SalesRepWidget";
SalesRepWidgetExt.requiredAccess ="SalesRep";
SalesRepWidgetExt.category='Customer'

const BaseStationWidgetExt = BaseStationWidget as Widget;
BaseStationWidgetExt.nameGiven = "BaseStationWidget";
BaseStationWidgetExt.requiredAccess = 'BaseStation';
BaseStationWidgetExt.category='Device'

const CustomerParentWidgetExt = CustomerParentWidget as Widget;
CustomerParentWidgetExt.nameGiven = "CustomerParentWidget";
CustomerParentWidgetExt.requiredAccess = 'CustomerParent';
CustomerParentWidgetExt.category='Customer'

const VoucherCheckerWidgetExt = VoucherCheckerWidget as Widget;
VoucherCheckerWidgetExt.nameGiven = "VoucherCheckerWidget";
VoucherCheckerWidgetExt.category='Other'
VoucherCheckerWidgetExt.requiredAccess = 'VoucherChecker'

const LockRSUpdateWidgetExt = LockRSUpdateWidget as Widget;
LockRSUpdateWidgetExt.nameGiven = "RSLockWidget";
LockRSUpdateWidgetExt.category ='Device';
LockRSUpdateWidgetExt.requiredAccess='LockRS'


const CreateGroupWidgetExt = CreateGroupWidget as Widget;
CreateGroupWidgetExt.nameGiven="CreateGroupWidget"
CreateGroupWidgetExt.category='Emergency'
CreateGroupWidgetExt.requiredAccess='Emergency'

const EmergencyEmailExt= EmergencyEmailWidget as Widget
EmergencyEmailExt.nameGiven="EmergencyEmailWidget"
EmergencyEmailExt.category='Emergency'
EmergencyEmailExt.requiredAccess='Emergency'

const WarrantySubscriptionWidgetExt = WarrantySubscriptionWidget as Widget;
WarrantySubscriptionWidgetExt.nameGiven = "WSWidget";
WarrantySubscriptionWidgetExt.category ='Device';
WarrantySubscriptionWidgetExt.requiredAccess='WarrantySubscription'

const BreakdownWidgetExt = BreakdownWidget as Widget;
BreakdownWidgetExt.nameGiven = "BreakdownWidget";
BreakdownWidgetExt.category ='Emergency'
BreakdownWidgetExt.requiredAccess='Emergency'

const ApproveCompanyWidgetExt=ApproveCompanyWidget as Widget;
ApproveCompanyWidgetExt.nameGiven='ApproveCompanyWidget'
ApproveCompanyWidgetExt.category='Customer'
ApproveCompanyWidgetExt.requiredAccess='HubspotMatch'

const SendTabletNotificationWidgetExt = SendTabletNotificationWidget as Widget;
SendTabletNotificationWidgetExt.nameGiven = "SendTabletNotificationWidget";
SendTabletNotificationWidgetExt.category = "Customer";
SendTabletNotificationWidgetExt.requiredAccess = "SuspendService";

const LockTabletWidgetExt = LockTabletWidget as Widget;
LockTabletWidgetExt.nameGiven = "LockTabletWidget";
LockTabletWidgetExt.category = "Customer";
LockTabletWidgetExt.requiredAccess = "SuspendService";

const MoveOrCopyCustomerLayoutsExt = MoveOrCopyCustomerLayoutsWidget as Widget;
MoveOrCopyCustomerLayoutsExt.nameGiven = "MoveOrCopyCustomerLayouts";
MoveOrCopyCustomerLayoutsExt.category = "Customer";
MoveOrCopyCustomerLayoutsExt.requiredAccess = "ManageCustomerLayouts";

const RestoreCustomerLayoutsExt = RestoreCustomerLayoutsWidget as Widget;
RestoreCustomerLayoutsExt.nameGiven = "RestoreCustomerLayouts";
RestoreCustomerLayoutsExt.category = "Customer";
RestoreCustomerLayoutsExt.requiredAccess = "ManageCustomerLayouts";

export default [
    ConnectWidgetExt,
    RobotNameWidgetExt,
    SetTabletUserLevelWidgetExt,
    CreateCustomerWidgetExt,
    CustomerNameWidgetExt,
    DeleteCustomerWidgetExt,
    SetTabletAppVersionWidgetExt,
    DeprecateDeviceWidgetExt,
    SalesRepWidgetExt,
    BaseStationWidgetExt,
    CustomerParentWidgetExt,
    VoucherCheckerWidgetExt,
    EmergencyEmailExt,
    LockRSUpdateWidgetExt,
    CreateGroupWidgetExt,
    WarrantySubscriptionWidgetExt,
    BreakdownWidgetExt,
    ApproveCompanyWidgetExt,
    SendTabletNotificationWidgetExt,
    LockTabletWidgetExt,
    UpdateCustomerAGsWidgetExt,
    MoveOrCopyCustomerLayoutsExt,
    RestoreCustomerLayoutsExt,
] as Widget[];
