import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AutoSizer from "react-virtualized-auto-sizer";
import DataGridV2 from "src/components/DataGrid";
import BaseView from "src/views/BaseView";
import ToggleTranslate from "../ToggleTranslate";
import useColumns from "./useColumns";
import useControlledData from "./useControlledData";
import TranslationProvider, { useTranslator } from "../TranslationProvider";
import { useAsync, useGetLatest } from "src/hooks";
import DeviceDataService, { DeviceData, DeviceDto } from "src/services/DeviceDataService";
import { customersSelector, excludeDeprecatedSelector } from "src/redux/app/selectors";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { isMobile } from "react-device-detect";
import { Row, IdType, ColumnInstance } from "react-table";
import ExportButton from "src/components/ExportButton";
import ExportCSV from "./ExportCSV";
import ConfigurationsService, { AccessGroup } from "src/services/ConfigurationsService";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    titleText: {
        marginBottom: theme.spacing(2),
        "& + *": {
            marginBottom: theme.spacing(2),
        },
    },
    container: {
        minHeight: 600,
        height: `calc(100vh - 100px)`,
        [theme.breakpoints.up("md")]: {
            height: "calc(98.5vh - 16px)",
        },
        [theme.breakpoints.up("xl")]: {
            height: "calc(99vh - 16px)",
        },
        "& > :last-child": {
            height: "calc(100% - 48px - 48px - 12px)",
            "& > :first-child": {
                width: "unset !important",
            },
        },
    },
    containerWrapper: {
        [theme.breakpoints.up("md")]: {
            overflow: "hidden",
        },
    },
}));

interface DeviceOverviewProps {
    deviceType: number;
    deviceName: string;
}

const KEYS_PER_PAGE = 45;

function formatDeviceDtos(data: DeviceDto[],prev:DeviceDto[]): DeviceDto[] {
    const ret = [] as DeviceDto[];
    for (const device of [...new Set([...data.map((d)=>d.deviceId),...prev.map((d)=>d.deviceId)])]) {
        const prevData = prev.find((d) => d.deviceId === device);
        const newData = data.find((d) => d.deviceId === device);
        if (newData === undefined)
            ret.push(prevData!);
        else if (prevData === undefined)
            ret.push(newData);
        else
        ret.push({deviceId: device, data: {...prevData?.data, ...newData?.data}});
    }
    return ret;
}

const DeviceOverview = React.memo(function DeviceOverview(
    props: DeviceOverviewProps
) {
    const classes = useStyles();
    const { deviceType, deviceName } = props;
    const location=useLocation();
    const excludeDeprecated = useSelector(excludeDeprecatedSelector);
    const [newdata,setNewdata]=useState([] as DeviceDto []);
    const [ldkeys,setLDKeys]=useState({keys:[],dictionary:{}} as any);
    const [loadedKeys,setLoadedKeys]=useState([] as string[]);

    const fetchKeys = useCallback(
        () => DeviceDataService.getLatestKeys(deviceType,true,deviceType === 1).then(async (k)=>
        {
            setLDKeys(k);
            const keys=[...k.keys];
            let firstKeys = [] as string[];

            const querySplit=location.search.split("&columns=");
            let columnsValueString="";
            if(querySplit.length>1){
                columnsValueString=querySplit[1];
            }
            else if(querySplit.length===1 && querySplit[0].includes("?columns=")){
                columnsValueString=querySplit[0].replace("?columns=",'');
            }

            const columnsObj = columnsValueString===''?{}:JSON.parse(decodeURIComponent(columnsValueString))
            if(columnsObj?.cols!==undefined){
                firstKeys = columnsObj.cols.filter((k: any)=>keys.includes(k));
            }else {
                const initState = localStorage.getItem("tmr/overview-table/"+deviceType);
                if(initState!==null){
                    const state = JSON.parse(initState);
                    if(state?.hiddenColumns!==undefined){
                        firstKeys = keys.filter((k)=>!state.hiddenColumns.includes(k));
                    }
                }
            }
            
            const orderedKeys = firstKeys.concat(keys.filter((k)=>!firstKeys.includes(k)));
            while (orderedKeys.length>0)
            {
                const newkeys=orderedKeys.splice(0,KEYS_PER_PAGE)
                await (DeviceDataService.getLatestByKeys(deviceType,newkeys).then((d)=>{
                    // setNewdata((prev)=>[...(formatDeviceDtos(d,prev)).map((dto)=>{return {...dto}})]);}))
                    setNewdata((prev)=>[...(formatDeviceDtos(d,prev)),{deviceId:orderedKeys.length*100000,data:{}}]);
                    setNewdata((prev)=>prev.slice(0,-1));
                }))
                    setLoadedKeys((prev)=>{return [...prev,...newkeys];});

            }
        }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deviceType, setNewdata,setLDKeys]
    );


    const fetchData = useCallback(
        () => DeviceDataService.getLatestDataFormatted(deviceType,{includeAccessGroups: deviceType === 1,includeDictionary: true}).then(r => {
            if (excludeDeprecated)
                r.data = r.data.filter(e => e.data.deprecated === undefined || e.data.deprecated === null);
            return r;
        }),
        [deviceType, excludeDeprecated]
    );

    const fetchAccessGroups = useCallback(
        () => {
            if(deviceType === 1)
                return ConfigurationsService.getAccessGroups();
            else
                return Promise.resolve(undefined);
        },
        [deviceType]
    );

    const { dto: customers, loaded: customersLoaded } = useSelector(
        customersSelector
    );


    const { value:latestKeys, error:errorKeys,exec:execKeys,  pending:pendingKeys } = useAsync(fetchKeys, {
        defaultPending: true,
    });

    const { value: accessGroups, error: errorAGs, exec: execAGs, pending: pendingAGs } = useAsync(fetchAccessGroups, {
        defaultPending: true,
    });


    const loading = pendingKeys || customersLoaded === false;

    const [currentTranslator, setCurrentTranslator] = useState({} as any);
    const [currentGrid, setCurrentGrid] = useState({columns: [], filteredData : []} as {columns: ColumnInstance<any>[], filteredData : Row<any>[]});

    return (
        <TranslationProvider
            deviceTypeId={deviceType}
            customers={customers}
            translationsDictionary={ldkeys.dictionary?? {}}
            accessGroups={accessGroups}
        >
            <BaseView loading={ldkeys.length===0 || Object.keys(ldkeys.dictionary).length===0 || loadedKeys.length===0} error={errorKeys} retry={execKeys}>
                <Container
                    component="main"
                    maxWidth={false}
                    className={classes.container}
                >
                    <div
                        style={{display: "flex",justifyContent: "space-between",flexDirection: "row"}}
                    >
                        <Typography
                            component="h1"
                            variant="h5"
                            className={classes.titleText}
                        >
                            {deviceName + "s Overview"}
                        </Typography>
                        <ExportButton
                            onClick={ExportCSV(currentGrid,currentTranslator)}
                        />
                    </div>
                    <Typography component="section">
                        Current status for your {deviceName.toLowerCase()}s can
                        be seen below. Clicking on{" "}
                        {isMobile ? "the ID column" : "a row"} will bring you to
                        the dashboard for the target device.
                    </Typography>
                    {
                        (ldkeys?.keys.length!==loadedKeys?.length)? <Typography component="section" style={{color:"red"}}>
                            To ensure data accuracy, the table is being populated column by column. If a value is visible, it is considered accurate.  If a value is not yet shown, please be patient as the data loads or check device dashboard for the latest information
                        </Typography>
                        :<Typography component="section" style={{color:"green"}}>
                            The table is fully populated and ready for use. 
                        </Typography>
                    }
                    <div>
                        <OverviewContent
                            {...{ keys:ldkeys.keys,loadedKeys, data:excludeDeprecated?newdata.filter((d)=>d.data.deprecated===null || d.data.deprecated===undefined):newdata, deviceType, deviceName,accessGroups, setCurrentTranslator, setCurrentGrid }}
                        />
                    </div>
                </Container>
            </BaseView>
        </TranslationProvider>
    );
});

interface OverviewContentProps {
    keys: string[];
    loadedKeys: string[];
    data: DeviceDto[];
    deviceType: number;
    deviceName: string;
    accessGroups: AccessGroup[] | undefined;
    setCurrentTranslator?: React.Dispatch<any>;
    setCurrentGrid?: React.Dispatch<any>;
}

function OverviewContent(props: OverviewContentProps) {
    const { keys, data: uncontrolledData, deviceType, deviceName, accessGroups, setCurrentTranslator, setCurrentGrid } = props;
    const { data } = useControlledData(deviceType, uncontrolledData);
    const { fixed: fixedHeader, keys: keyHeaders } = useColumns({
        deviceType,
        deviceName,
        keys,
        accessGroups,
    })!

    // Navigate on click
    const dispatch = useDispatch();
    const navigate = useCallback(
        (original: DeviceDto) => {
            let url = "/devices/";
            if (deviceType === 1)
                url += "robots/"+original.deviceId;
            else if (deviceType === 2)
                if (original.data["robot"]!==undefined&&original.data["robot"]!==null&&original.data["robot"]!=="")
                    url += "robots/"+original.data["robot"]+"/?tablet="+original.deviceId;
                else
                    url += "tablets/"+original.deviceId;
            else if (deviceType === 3)
                url += "referencestations/"+original.deviceId;
            else
                url += deviceType+"/"+original.deviceId;
            dispatch(push(url));
        },
        [dispatch, deviceType]
    );

    const translator = useTranslator();
    const getTranslator = useGetLatest(translator);
    useEffect(() => {
        if (setCurrentTranslator !== undefined)
            setCurrentTranslator(getTranslator());
    });
    const globalFilter = useCallback(
        (
            rows: Row<DeviceDto>[],
            columnIds: IdType<DeviceDto>[],
            filterValue: any
        ) => {
            if(filterValue?.keyword !== undefined){
                if(filterValue.strict && filterValue.keyword.length){
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id])) === filterValue.keyword;
                        });
                    });
                }
                else{ 
                    const keyword = String(filterValue.keyword).toLowerCase();
                    return rows.filter((row) => {
                        return columnIds.some((id) => {
                            return String(getTranslator().translate(id, row.values[id]))
                                .toLowerCase()
                                .includes(keyword);
                        });
                    });
                }
            }
            else{
                filterValue = String(filterValue).toLowerCase();
                return rows.filter((row) => {
                    return columnIds.some((id) => {
                        return String(getTranslator().translate(id, row.values[id]))
                            .toLowerCase()
                            .includes(filterValue);
                    });
                });
            }
        },
        [getTranslator]
    );

    (globalFilter as any).autoRemove = useCallback((val: any) => !val, []);

    const toolbarProps = useMemo(
        () => ({
            LeftAdornment: <ToggleTranslate />,
            hideColumnsProps: {
                filter: (
                    columns: ColumnInstance<DeviceDto>[],
                    filter: string
                ) => {
                    if (filter) {
                        const reg = new RegExp(filter.trim(), "i");
                        return columns.filter((c) =>
                            reg.test(getTranslator().translateKey(c.id))
                        );
                    }
                    return columns;
                },
            },
            enableStrictSearch: true,
        }),
        [getTranslator]
    );

    return (
        <AutoSizer>
            {({ height, width }) => (
                <DataGridV2<DeviceDto>
                    name={`tmr/overview-table/${deviceType}`}
                    fixedColumn={fixedHeader}
                    columns={keyHeaders}
                    data={data}
                    toolbarProps={toolbarProps}
                    onCellClick={navigate}
                    limitClickToFixed={isMobile}
                    globalFilter={globalFilter}
                    height={height}
                    width={width}
                    setCurrentGrid={setCurrentGrid}
                />
            )}
        </AutoSizer>
    );
}

(DeviceOverview as any).whyDidYouRender = true;

export default DeviceOverview;
