import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Customer } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useToast } from "src/hooks";
import { customersArraySelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import {  Checkbox, Typography } from "@material-ui/core";


export interface Layout {
    id: number;
    originalId: number;
    name: string;
    template: string;
    tablet: number;
}

type RestoreCustomerLayoutsInput = {
    customer: Customer | null;
    ids: Layout[];
};

function restoreLayouts(input: RestoreCustomerLayoutsInput) {
    return ConfigurationsService.restoreCustomerLayouts(input.customer!.id, input.ids.map(l =>l.originalId)).then(() => input);
}

export default function RestoreCustomerLayoutsWidget({ widgetName ,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<RestoreCustomerLayoutsInput>({
        defaultValues: { customer: null, ids: []},
    });

    const { displayToast } = useToast();

    const customer = watch("customer");
    const layouts = watch("ids");
    const [fromLayouts, setFromLayouts] = useState([] as Layout[]);

    const customers = useSelector(customersArraySelector);
           
    useEffect(() => {
        register("customer", defaultRules);
        register("ids", {required: "Select at leat 1 layout", validate: (value) => {
            if(value.length === 0)
                return "Select at leat 1 layout";
            else
                return true;
        }});
    }, [register]);

    const onComplete = useCallback(
        (input: RestoreCustomerLayoutsInput) => {
            reset();
            displayToast({
                message: `Successfully restored ${layouts.length} layouts for ${
                    input.customer!.name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [reset, displayToast, layouts.length]
    );
    
    const { exec: restoreLayoutsExec, pending: restoringLayouts, error } = useAsync(
        restoreLayouts,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const onSelectCustomer = useCallback(
        async () =>{
            setValue("ids", [])
            if(customer !== null){
                const layouts = await ConfigurationsService.getDeletedCustomerLayouts(customer.id);
                setFromLayouts(layouts); 
            }
            else{
                return Promise.resolve(()=> setFromLayouts([]));
            }
        },[customer, setValue]
    );

    const { exec: fetchCustomerLayouts, pending: fetchingLayouts, error: fetchingError } = useAsync(
        onSelectCustomer,
        {
            immediate: false,
        }
    );

    const loading = restoringLayouts || customers.length === 0;

    useEffect(() => {
        fetchCustomerLayouts();
    },[fetchCustomerLayouts, customer]);

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Restore customer layouts"
            subTitle="Restore deleted layouts of a customer."
            onSubmit={handleSubmit(restoreLayoutsExec)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            
            <AutocompleteV2
                selectOnTab
                value={customer}
                disabled={loading || fetchingLayouts}
                options={customers}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such customer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Customer"
                            placeholder="Choose a customer"
                            required
                            {...getErrorProps(errors as any, "customer")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("customer", val);
                }}
            />
            <div>
                <AutocompleteV2
                    //selectOnTab
                    multiple
                    disableCloseOnSelect
                    limitTags={5}
                    groupBy={(option) => option.template}
                    renderGroup={(props) => {
                        
                        return <div key={props.group}>
                        <Checkbox
                            color="primary"
                            style={{ marginRight: 8 }}
                            checked={layouts.filter(l => l.template === props.group).length === fromLayouts.filter(l => l.template === props.group).length}
                            indeterminate={layouts.filter(l => l.template === props.group).length > 0 && layouts.filter(l => l.template === props.group).length < fromLayouts.filter(l => l.template === props.group).length}
                            onChange={(ev) => {
                                if(ev.target.checked){
                                    const toAdd = fromLayouts.filter(l => l.template === props.group && !layouts.includes(l));
                                    setValue("ids", layouts.concat(toAdd));
                                }
                                else{
                                    setValue("ids", layouts.filter(l => l.template !== props.group));
                                }
                            }}
                        />
                        <Typography color="primary" component="span">{props.group}</Typography>
                        {props.children}
                    </div>
                    }}
                    value={layouts}
                    disabled={loading || customer === null  || fetchingLayouts}
                    options={fromLayouts}
                    getOptionLabel={(r) => r.name}
                    renderOption={(option, props) => {
                        return (
                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <Checkbox
                            size = "small"
                                style={{ marginRight: 8 }}
                                checked={props.selected}
                            />
                            {option.name}
                        </div>
                        );
                    }}
                    getOptionSelected={(a, b) => a.id === b.id}
                    noOptionsText="No such layout"
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label="Layouts"
                                placeholder="Choose layouts"
                                required
                                {...getErrorProps(errors as any, "ids")}
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setValue("ids", val);
                    }}
                />
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        size="small"
                        color="primary"
                        disabled={loading || fromLayouts.length === 0 || fetchingLayouts}
                        checked={fromLayouts.length === layouts.length && fromLayouts.length > 0}
                        indeterminate={layouts.length > 0 && layouts.length < fromLayouts.length}
                        onChange={(e) => {
                            if(e.target.checked){
                                setValue("ids", fromLayouts);
                            }
                            else{
                                setValue("ids", []);
                            }
                        }}
                        tabIndex={-1}
                    />
                    <label>
                        {"Select all"}
                    </label>
                </div>
                <Typography variant="body1"  color="textSecondary">Total Layouts: {fetchingLayouts ? "loading..." : fromLayouts.length}</Typography>
                <Typography variant="body1"  color="textSecondary">Selected Layouts: {layouts.length}</Typography>
                {fetchingError && <Typography variant="body1"  color="error">{fetchingError?.message}</Typography>}
            </div>
           
            
        </BaseWidget>
    );
}
